
import { defineComponent } from "vue";
import DatepickerInput from "@/components/form/DatepickerInput.vue";
import SearchInput from "@/components/form/SearchInput.vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import ReturnParcelListItem from "@/components/return-parcels/ReturnParcelListItem.vue";
import Modal from "@/components/ui/Modal.vue";
import Pagination from "@/components/ui/Pagination.vue";
import returnParcelsService from "@/services/return-parcels.service";
import { PaginationMeta } from "@/models/PaginationMeta";
import Spinner from "@/components/ui/Spinner.vue";
import _ from "lodash";
import { FormElementData } from "@/models/FormElementData";
import DynamicForm from "@/components/form/DynamicForm.vue";
import { clearData, PossibleValues, Values } from "@/common/FormTools";
import { ReturnParcelsListItem } from "@/models/ReturnParcelsListItem";
import { ReturnParcelsStatus } from "@/models/ReturnParcelsStatus";
import { ReturnParcelsParams } from "@/models/ReturnParcelsParams";
import { prepareReturnParcelFullFormData } from "@/common/ReturnParcelFormTools";
import { AccountDetailsResponse } from "@/models/AccountDetailsResponse";
import accountService from "@/services/account.service";
import countriesService from "@/services/countries.service";

interface State {
  formTitle: string;
  showReturnParcelFormModal: boolean;
  returnParcels: Array<ReturnParcelsListItem>;
  isLoading: boolean;
  pagination?: PaginationMeta;
  search: string;
  searchedPhrase: string;
  status: ReturnParcelsStatus;
  dateFrom: string;
  dateTo: string;
  formLoading: boolean;
  formSaving: boolean;
  formValidation: unknown;
  formValue: unknown;
  formData: FormElementData;
  checkedReturnParcels: Array<number>;
  isReturnParcelsPageChecked: boolean;
  accountDetails: AccountDetailsResponse & {
    country_id?: number;
  };
}

export default defineComponent({
  name: "ReturnParcelsList",
  components: {
    ReturnParcelListItem,
    DynamicForm,
    Spinner,
    Pagination,
    Modal,
    DefaultButton,
    SearchInput,
    DatepickerInput,
  },
  data(): State {
    return {
      formTitle: "",
      showReturnParcelFormModal: false,
      returnParcels: [],
      isLoading: true,
      pagination: undefined,
      search: "",
      searchedPhrase: "",
      status: ReturnParcelsStatus.ALL,
      dateFrom: "",
      dateTo: "",
      formLoading: true,
      formSaving: false,
      formValidation: {},
      formValue: {},
      formData: {
        id: "empty-form",
        type: "form",
      },
      checkedReturnParcels: [],
      isReturnParcelsPageChecked: false,
      accountDetails: {},
    };
  },
  async mounted() {
    await this.getList();
    this.formData = await prepareReturnParcelFullFormData();
    this.accountDetails = await this.getAccountDetails();
    this.formLoading = false;
  },
  methods: {
    async getAccountDetails() {
      let accountDetails = {} as AccountDetailsResponse & {
        country_id?: number;
      };
      await accountService
        .getAccountDetails()
        .then((response) => {
          accountDetails = response.data;
        })
        .catch(() => {
          accountDetails = {};
        });
      await countriesService.get().then((response) => {
        const countryName = _.get(accountDetails, "country");
        if (countryName) {
          accountDetails.country_id =
            _.reduce(
              response.data.countries,
              (countriesObject, countryData) => {
                countriesObject[countryData.name] = countryData.id;
                return countriesObject;
              },
              {} as Record<string, number>
            )[countryName] ?? undefined;
        }
      });
      return _.omit(accountDetails);
    },
    checkReturnParcelsPage() {
      if (this.isReturnParcelsPageChecked) {
        this.checkedReturnParcels = _.union(
          this.checkedReturnParcels,
          this.pageReturnParcelsIds
        );
      } else {
        this.checkedReturnParcels = _.difference(
          this.checkedReturnParcels,
          this.pageReturnParcelsIds
        );
      }
    },
    checkReturnParcel(returnParcelId: number) {
      if (_.includes(this.checkedReturnParcels, returnParcelId)) {
        this.checkedReturnParcels = _.without(
          this.checkedReturnParcels,
          returnParcelId
        );
      } else {
        this.checkedReturnParcels = _.union(this.checkedReturnParcels, [
          returnParcelId,
        ]);
      }
      this.isReturnParcelsPageChecked = !_.difference(
        this.pageReturnParcelsIds,
        this.checkedReturnParcels
      ).length;
    },
    isReturnParcelChecked(returnParcelId: number) {
      return _.includes(this.checkedReturnParcels, returnParcelId);
    },
    mapAccountDetailsToReturnParcelForm(): Values {
      const values = {} as Values;
      const addressFields = [
        "address_first_line",
        "address_second_line",
        "city",
        "state",
        "zip_code",
      ];
      const receiverAddress = _.omit({
        company_name: this.accountDetails?.company_name ?? undefined,
        email: this.accountDetails?.contact_email ?? undefined,
        phone: this.accountDetails?.phone?.main ?? undefined,
        country_id: this.accountDetails?.country_id ?? undefined,
        vat: this.accountDetails?.vat ?? undefined,
        eori: this.accountDetails?.eori ?? undefined,
        ..._.pick(this.accountDetails?.main_address, addressFields),
      });
      if (Object.keys(receiverAddress).length) {
        values.receiver_address = receiverAddress as PossibleValues;
      }
      return _.omit(values);
    },
    createNewReturnParcel(): void {
      this.formTitle = "return_parcels.create.title";
      this.showReturnParcelFormModal = true;
      this.formValue = this.mapAccountDetailsToReturnParcelForm();
      this.formValidation = {};
    },
    cancelAction() {
      this.showReturnParcelFormModal = false;
    },
    async submitAction(values: Values) {
      this.formSaving = true;
      let action;
      action = returnParcelsService.save(clearData(values));
      await action
        .then(() => {
          this.showReturnParcelFormModal = false;
          this.getList();
        })
        .catch((error) => {
          this.formValidation = error?.response?.data?.details ?? {};
          this.formValue = values;
          document.getElementsByClassName("modal__content")[0].scrollTo(0, 0);
        });
      this.formSaving = false;
    },
    searchUpdate(newSearch: string) {
      this.search = newSearch;
    },
    dateFromUpdate(newDateFrom: string) {
      this.dateFrom = newDateFrom;
      this.getList();
    },
    dateToUpdate(newDateTo: string) {
      this.dateTo = newDateTo;
      this.getList();
    },
    changePagination(page: number, perPage: number) {
      return this.getList(page, perPage, true);
    },
    async getList(page = 1, perPage = 10, keepCheckedReturnParcels = false) {
      this.isLoading = true;

      this.searchedPhrase = this.search;

      const params = _.pickBy({
        page: page,
        per_page: perPage,
        search: this.search,
        date_from: this.dateFrom,
        date_to: this.dateTo,
      }) as ReturnParcelsParams;

      const { data } = await returnParcelsService.list(params);
      this.returnParcels = data.data || [];
      this.pagination = data.meta;

      if (keepCheckedReturnParcels) {
        this.isReturnParcelsPageChecked = !_.difference(
          this.pageReturnParcelsIds,
          this.checkedReturnParcels
        ).length;
      } else {
        this.isReturnParcelsPageChecked = false;
        this.checkedReturnParcels = [];
      }

      this.isLoading = false;
    },
  },
  computed: {
    pageReturnParcelsIds(): Array<number> {
      return _.reduce(
        this.returnParcels,
        (returnParcelsIds, returnParcel) => {
          if (returnParcel?.id) {
            returnParcelsIds.push(returnParcel.id);
          }
          return returnParcelsIds;
        },
        [] as Array<number>
      ) as Array<number>;
    },
  },
});
