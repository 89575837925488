import { AxiosPromise } from "axios";
import axiosClient from "@/axios";
import { ReturnParcelsResponse } from "@/models/ReturnParcelsResponse";
import { ReturnParcelResponse } from "@/models/ReturnParcelResponse";
import { ReturnParcelResourceRequest } from "@/models/ReturnParcelResourceRequest";
import { ReturnParcelsParams } from "@/models/ReturnParcelsParams";

const returnParcelsService = {
  list(params: ReturnParcelsParams): AxiosPromise<ReturnParcelsResponse> {
    return axiosClient.get("/returns", {
      params: params,
    });
  },
  details(returnParcelId: number): AxiosPromise<ReturnParcelResponse> {
    return axiosClient.get(`/returns/${returnParcelId}`);
  },
  save(
    request: ReturnParcelResourceRequest
  ): AxiosPromise<ReturnParcelResponse> {
    return axiosClient.post("/returns", request);
  },
  downloadLabels(returnParcelsIds: number[]): AxiosPromise {
    return axiosClient.post("returns/labels", {
      ids: returnParcelsIds,
    });
  },
};

export default returnParcelsService;
