
import { defineComponent } from "vue";
import InfoField from "@/components/ui/InfoField.vue";
import SectionWrapper from "@/components/layout/SectionWrapper.vue";
import returnParcelsService from "@/services/return-parcels.service";
import { ParcelResponse } from "@/models/ParcelResponse";
import Spinner from "@/components/ui/Spinner.vue";

export default defineComponent({
  name: "ReturnParcelDetails",
  components: { Spinner, SectionWrapper, InfoField },
  data() {
    return {
      returnParcel: {} as ParcelResponse,
      isLoading: true,
    };
  },
  props: {
    returnParcelId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    openTrackingUrl: (tracking_url: string) => {
      window.open(tracking_url, "_blank");
    },
  },
  async mounted() {
    this.isLoading = true;
    const response = await returnParcelsService.details(this.returnParcelId);

    this.returnParcel = response.data;
    this.isLoading = false;
  },
});
