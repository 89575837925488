
import { defineComponent, PropType } from "vue";
import moment from "moment";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import Modal from "@/components/ui/Modal.vue";
import ExpandButton from "@/components/ui/ExpandButton.vue";
import downloadService from "@/services/download.service";
import _ from "lodash";
import { prepareErrors } from "@/common/Tools";
import ReturnParcelDetails from "@/components/return-parcels/ReturnParcelDetails.vue";
import { ReturnParcelsListItem } from "@/models/ReturnParcelsListItem";

export default defineComponent({
  name: "ReturnParcelListItem",
  components: {
    ReturnParcelDetails,
    ExpandButton,
    Modal,
    DefaultButton,
  },
  emits: ["checkReturnParcel", "editReturnParcel"],
  data() {
    return {
      isExpanded: false,
      showDetails: false,
      moment,
      isDownloading: false,
      downloadService,
    };
  },
  props: {
    returnParcel: {
      type: Object as PropType<ReturnParcelsListItem>,
      required: true,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valid() {
      return _.isEmpty(this.returnParcel.validation_errors);
    },
  },
  methods: {
    prepareErrors,
    openTrackingUrl: (tracking_url: string) => {
      window.open(tracking_url, "_blank");
    },
    importImage: (supplier: string) => {
      let res = null;
      try {
        res = require(`../../assets/images/${supplier}.png`);
      } catch (e) {
        console.log(`Image not found for ${supplier}`);
        res = require(`../../assets/images/B2cEurope.png`);
      }
      return res;
    },
  },
});
